import React from "react";
import { Row, Col, Button, Container, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import UserNotSignedInCard from "./UserNotSignedInCard";
import UserSignedInCard from "./UserSignedInCard";
import { useUserSubscriptionStatus } from "../../hooks/useUserSubscriptionStatus";
import { useQuery } from "react-query";
import { getAllTests } from "../../api/digitalSATApi";
import "./StartPageTopRow.css";

const StartPageTopRow = () => {
  const { user } = UserAuth();
  const navigate = useNavigate();

  const isActiveSubscription = useUserSubscriptionStatus();

  const { isLoading, refetch, data, isSuccess, isFetching } = useQuery(
    "all-digital-sat-tests",
    getAllTests,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: !!user, // Fetch only when the user is available
    }
  );

  const arrayOfImages = [
    {
      src: "../images/openclipart/johnny-automatic-open-book.svg",
      alt: "Open book illustration",
    },
    {
      src: "../images/openclipart/math_pi_symbol.svg",
      alt: "Math pi symbol",
    },
    {
      src: "../images/openclipart/lightbulb.svg",
      alt: "Lightbulb illustration",
    },
    {
      src: "../images/openclipart/simplebraindiagram.svg",
      alt: "Simple brain diagram",
    },
  ];

  const handleSubscribeClick = () => {
    if (!user) {
      navigate("/signup");
    } else {
      navigate("/computertests");
    }
  };

  const generateArtfulImage = (rows, columns) => {
    const arrayOfJSXRows = [];
    let columnStart = 0;

    for (let i = 0; i < rows; i++) {
      const arrayOfJSXColumns = [];

      for (let j = 0; j < columns; j++) {
        const imageIndex = (columnStart + j) % arrayOfImages.length;
        const image = arrayOfImages[imageIndex];
        arrayOfJSXColumns.push(
          <Col key={`col-${i}-${j}`}>
            <img
              className="tiled-image-welcome m-4"
              src={image.src}
              alt={image.alt}
            />
          </Col>
        );
      }
      columnStart++;

      arrayOfJSXRows.push(<Row key={`row-${i}`}>{arrayOfJSXColumns}</Row>);
    }

    return arrayOfJSXRows;
  };

  const determineAgendaMessage = () => {
    return "Six full-length Digital SAT tests, Video Explanations, and 1,600+ brand new problems.";
  };

  const determineSubsribeButton = () => {
    if (!isActiveSubscription) {
      return (
        <>
          <Button
            size="lg"
            className="mt-4 purple-button hover-enlarge-more text-center d-none d-lg-block"
            onClick={handleSubscribeClick}
          >
            Take a Free Digital SAT Now!
          </Button>
          <Button
            className="my-1 purple-button hover-enlarge-more text-center d-block d-lg-none"
            onClick={handleSubscribeClick}
          >
            Take a Free Digital SAT Now!
          </Button>
        </>
      );
    } else if (isActiveSubscription) {
      return (
        <Button
          size="lg"
          className="my-1 purple-button hover-enlarge-more text-center d-none d-md-block"
          onClick={handleSubscribeClick}
        >
          Take a Digital SAT Now!
        </Button>
      );
    }
  };

  const createMainJSX = () => {
    return (
      <Container fluid className="p-0">
        <Col className="d-block d-sm-none main-font">
          <Row className="artful-row-of-images w-100">
            {generateArtfulImage(3, 2)}
          </Row>
        </Col>
        <Col className="d-none d-lg-block main-font">
          <Row className="artful-row-of-images w-100">
            {generateArtfulImage(3, 6)}
          </Row>
        </Col>
        <Col className="d-none d-sm-block d-lg-none">
          <Row className="artful-row-of-images w-100">
            {generateArtfulImage(3, 3)}
          </Row>
        </Col>
        <Row className="position-absolute text-white d-flex justify-content-center align-items-center h-100 w-100 p-0 px-4">
          <Col xs={12} sm={12} md={4} lg={5} xl={6} className="">
            {/* for XS sized screens */}
            <h1 className="main-digital-sat-font-xs d-block d-sm-none text-center my-0 mt-4 amatic-font font-weight-very-bold">
              Digital SAT<span className="fs-6 registered-symbol-md">®</span>{" "}
              Prep
            </h1>
            {/* for SM sized screens */}
            <h1 className="main-digital-sat-font-sm d-none d-sm-block d-md-none text-start amatic-font font-weight-very-bold text-center">
              Digital SAT<span className="fs-6 registered-symbol-xl">®</span>{" "}
              Prep
            </h1>
            {/* for MD sized screens */}
            <h1 className="main-digital-sat-font-md d-none d-md-block d-lg-none text-start amatic-font font-weight-very-bold text-center">
              Digital SAT<span className="fs-6 registered-symbol-md">®</span>{" "}
              Prep
            </h1>
            {/* for LG sized screens */}
            <h1 className="main-digital-sat-font-lg d-none d-lg-block d-xl-none text-start amatic-font font-weight-very-bold text-center">
              Digital SAT<span className="fs-6 registered-symbol-lg">®</span>{" "}
              Prep
            </h1>
            {/* for XL sized screens */}
            <h1 className="main-digital-sat-font-xl d-none d-xl-block text-start amatic-font font-weight-very-bold text-center">
              Digital SAT<span className="fs-6 registered-symbol-xl">®</span>{" "}
              Prep
            </h1>

            <br></br>

            {!user && (
              <>
                {/* For XS sized screens */}
                <h2 className="fs-5 d-block d-sm-none text-center">
                  {determineAgendaMessage()}
                </h2>
                {/* For SM sized screens */}
                <h2 className="fs-5 d-none d-sm-block d-md-none text-center">
                  {determineAgendaMessage()}
                </h2>
                {/* For MD sized screens */}
                <h2 className="fs-5 d-none d-md-block d-lg-none text-center">
                  {determineAgendaMessage()}
                </h2>
                {/* For LG sized screens */}
                <h2 className="fs-5 d-none d-lg-block d-xl-none text-center">
                  {determineAgendaMessage()}
                </h2>
                {/* For XL sized screens */}
                <h2 className="fs-5 d-none d-xl-block text-center">
                  {determineAgendaMessage()}
                </h2>
              </>
            )}

            {user && (
              <>
                {/* For XS sized screens */}
                <h2 className="fs-5 d-block d-sm-none text-center my-0 mb-3">
                  {determineAgendaMessage()}
                </h2>
                {/* For SM sized screens */}
                <h2 className="fs-5 d-none d-sm-block d-md-none ps-2">
                  {determineAgendaMessage()}
                </h2>
                {/* For MD sized screens */}
                <h2 className="fs-5 d-none d-md-block d-lg-none">
                  {determineAgendaMessage()}
                </h2>
                {/* For LG sized screens */}
                <h2 className="fs-5 ms-4 d-none d-lg-block d-xl-none">
                  {determineAgendaMessage()}
                </h2>
                {/* For XL sized screens */}
                <h2 className="fs-5 ms-4 d-none d-xl-block">
                  {determineAgendaMessage()}
                </h2>
              </>
            )}
            <Row className="">
              <Col className="d-flex justify-content-center my-1">
                {determineSubsribeButton()}
              </Col>
            </Row>
            {!isActiveSubscription && (
              <Row className="d-flex justify-content-center">
                <Col>
                  <h4 className="fs-6 text-center mt-3">
                    <a
                      href="/subscribe"
                      className="text-white pe-1 hover-pointer hover-enlarge-more"
                    >
                      Subscribe
                    </a>
                    for only $8/month and get access to all tests!
                  </h4>
                </Col>
              </Row>
            )}
          </Col>

          <Col
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={6}
            className="d-flex align-middle align-items-center justify-content-center mt-0"
          >
            {!user && <UserNotSignedInCard />}
            {user && (
              <>
                {/* <div className='d-block d-sm-none'>
              <Button className='purple-button hover-enlarge'
                onClick={() => handleSubscribeClick()}
              >
                Take a test!
              </Button>
            </div> */}
                <div className="d-none d-sm-block d-flex justify-content-center">
                  <UserSignedInCard />
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <Container fluid className="p-0">
      {/* for XS sized screens */}
      <Row
        className="artful-row-after-header ps-3 d-block d-sm-none"
        style={{ height: "27rem" }}
      >
        {createMainJSX()}
      </Row>
      {/* for SM sized screens */}
      <Row
        className="artful-row-after-header ps-3 d-none d-sm-block d-md-none"
        style={{ height: "44rem" }}
      >
        {createMainJSX()}
      </Row>
      {/* for MD sized screens */}
      <Row
        className="artful-row-after-header ps-3 d-none d-md-block d-lg-none"
        style={{ height: "26rem" }}
      >
        {createMainJSX()}
      </Row>
      {/* for LG sized screens */}
      <Row
        className="artful-row-after-header ps-3 d-none d-lg-block d-xl-none"
        style={{ height: "28rem" }}
      >
        {createMainJSX()}
      </Row>
      {/* for XL sized screens */}
      <Row
        className="artful-row-after-header ps-3 d-none d-xl-block"
        style={{ height: "26rem" }}
      >
        {createMainJSX()}
      </Row>
    </Container>
  );
};

export default StartPageTopRow;
