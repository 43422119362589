import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Container, Card, Stack, Button, DropdownButton } from 'react-bootstrap';
import "./StartPage.css";
import { UserAuth } from './../context/AuthContext';
import { Link } from 'react-router-dom';

import { useQuery } from 'react-query';
import * as userApi from '../api/userApi';

import StartPageHeader from './StartPageHeader';
import UnderConstructionModal from './UnderConstructionModal';
import StartPageTopRow from './start-page-assets/StartPageTopRow';
import { getSubscriptionDetails } from '../api/userApi';
import { useUserSubscriptionStatus } from '../hooks/useUserSubscriptionStatus';
import TutorToolsBanner from './start-page-assets/TutorToolsBanner';

const StartPage = (props) => {

    const navigate = useNavigate();
    const { user, logout } = UserAuth();

    const [showUnderConstructionModal, setShowUnderConstructionModal] = useState(false);
    const [email, setEmail] = useState(user ? user?.email : "");
    const [activeTestModules, setActiveTestModules] = useState([]);
    const [activeTopicModules, setActiveTopicModules] = useState([]);
    const [updatedUserFromDB, setUpdatedUserFromDB] = useState({});

    const isActiveSubscription = useUserSubscriptionStatus();


    const { isLoading: isLoadingDetailedUser, refetch: refreshCurrentUser, data: detailedUser, isSuccess } = useQuery(
        ["detailed-user", email], () => userApi.getDetailedUser(email));



    const arrayOfImages = [
        <img className="tiled-image-welcome m-4" src="/images/openclipart/johnny-automatic-open-book.svg" alt="open book clip art for background" />,
        <img className="tiled-image-welcome m-4" src="/images/openclipart/math_pi_symbol.svg" alt="math pi symbol clip art for background" />,
        <img className="tiled-image-welcome m-4" src="/images/openclipart/lightbulb.svg" alt="lightbulb clip art for background" />,
        <img className="tiled-image-welcome m-4" src="/images/openclipart/simplebraindiagram.svg" alt="brain clip art for background" />
    ]

    useEffect(() => {
        if (user) {
            setEmail(user?.email);
            if (user?.email) {
                setShowUnderConstructionModal(false);
            }

        }
    }, [user])

    useEffect(() => {
        if (detailedUser) {
            setUpdatedUserFromDB(detailedUser);
        }
    }, [detailedUser])

    useEffect(() => {

        const tempPracticeModules = updatedUserFromDB.practiceModules;
        let tempActivePracticeModules = [];
        let tempPassivePracticeModules = [];

        if (tempPracticeModules) {
            if (tempPracticeModules[0]) {
                tempActivePracticeModules = tempPracticeModules.filter(element => element[0][0] === "MockAssigned");
                tempPassivePracticeModules = tempPracticeModules.filter(element => element[0][0] === "MockCompleted");
                setActiveTestModules(tempActivePracticeModules.filter(element => element[0][1] === "Test Review"));
                setActiveTopicModules(tempActivePracticeModules.filter(element => element[0][1] === "Topic Review"));
            }
        }

    }, [updatedUserFromDB])

    const handleSubscribeClick = () => {
        if (!user) {
            navigate('/signup');
        } else {
            navigate('/subscribe');
        }
    }

    return (
        <Container fluid className='p-0'>
            <StartPageHeader />
            <StartPageTopRow />
            <Row className='bg-color-white py-4'>
                <h2 className='text-center text-green font-weight-bold'>Simple Steps to Effective Test Prep:</h2>
            </Row>
            <Row className='bg-color-offwhite pt-3'>

                <Col sm={6} className='d-flex align-middle align-items-center justify-content-center px-2 my-2'>
                    <Button variant="link" className="m-0 p-0 text-decoration-none bg-color-offwhite rounded-0 w-100 d-flex align-middle align-items-center justify-content-center"
                        onClick={() => { navigate('/tests') }}
                        aria-label="Take a Test">
                        <Card className='mt-2 p-3 text-center d-flex align-middle align-items-center justify-content-center navigation-card hover-enlarge w-100 rounded-4' style={{ maxWidth: "30rem" }}
                        >
                            <div className="circle-icon-container d-flex align-middle align-items-center justify-content-center m-4 mx-2">
                                <img className="navigation-card-icon m-4" src="/images/openclipart/PrinterKiller-Paper.svg" alt="image of a piece of paper representing a test" />
                            </div>

                            <h3 className='display-5 font-weight-very-bold text-black amatic-font'>#1. Test.</h3>
                            <Card.Body className='d-flex align-items-center'>
                                <Card.Text className='text-black purple-dashed-border px-2 py-2 bg-color-purple-gradiant text-white fs-5' style={{ minHeight: "4rem" }}>
                                    Resolve Prep offers the 6 highest quality Digital SAT tests on the market
                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </Button>
                </Col>

                <Col sm={6} className='d-flex align-middle align-items-center justify-content-center px-2 my-2'>
                    <Button variant="link" className="m-0 p-0 text-decoration-none bg-color-offwhite rounded-0 w-100 d-flex align-middle align-items-center justify-content-center"
                        onClick={() => { navigate('/learn') }}
                        aria-label="Learn Topics">
                        <Card className='mt-2 p-3 text-center d-flex align-middle align-items-center justify-content-center navigation-card add-shadow hover-enlarge w-100 rounded-4' style={{ maxWidth: "30rem" }}
                        >
                            <div className="circle-icon-container d-flex align-middle align-items-center justify-content-center m-4">
                                <img className="navigation-card-icon m-4" src="/images/openclipart/learn.svg" alt="image of thoughts of one brain going into another brain" />
                            </div>

                            <h3 className='display-5 font-weight-very-bold text-black amatic-font'>#2. Learn.</h3>
                            <Card.Body className='d-flex align-items-center'>

                                <div className='purple-dashed-border px-2 py-2 bg-color-purple-gradiant text-white fs-5' style={{ minHeight: "4rem" }}>Video and written explanations for the problems, on-demand.</div>

                            </Card.Body>
                        </Card>
                    </Button>
                </Col>

                <Col sm={6} className='d-flex align-middle align-items-center justify-content-center px-2 my-2'>
                    <Button variant="link" className="m-0 p-0 text-decoration-none bg-color-offwhite rounded-0 w-100 d-flex align-middle align-items-center justify-content-center"
                        onClick={() => { navigate('/practicemodules') }}
                        aria-label="Focused Practice">
                        <Card className='mt-2 p-3 text-center d-flex align-middle align-items-center justify-content-center navigation-card add-shadow hover-enlarge w-100 rounded-4' style={{ maxWidth: "30rem" }}
                        >
                            <div className="circle-icon-container d-flex align-middle align-items-center justify-content-center m-4">
                                <img className="navigation-card-icon m-4" src="/images/openclipart/pencil_in_fist.svg" alt="image of pencil grasped tightly in fist" />
                            </div>

                            <h3 className='display-5 font-weight-very-bold text-black amatic-font'>#3. Practice</h3>
                            <Card.Body className='d-flex align-items-center'>

                                <div className='purple-dashed-border px-2 py-2 d-flex align-items-center bg-color-purple-gradiant text-white fs-5' style={{ minHeight: "4rem" }}>In addition to the tests, over 900 practice problems and counting, organized by topic</div>

                            </Card.Body>
                        </Card>
                    </Button>
                </Col>

                <Col sm={6} className='d-flex align-middle align-items-center justify-content-center px-2 my-2'>
                    <Button variant="link" className="m-0 p-0 text-decoration-none bg-color-offwhite rounded-0 w-100 d-flex align-middle align-items-center justify-content-center"
                        onClick={() => { navigate('/tests') }}
                        aria-label="Take another Test">
                        <Card className='mt-2 p-3 text-center d-flex align-middle align-items-center justify-content-center navigation-card add-shadow hover-enlarge w-100 rounded-4' style={{ maxWidth: "30rem" }}
                        >
                            <div className="circle-icon-container d-flex align-middle align-items-center justify-content-center m-4">
                                <img className="navigation-card-icon m-4" src="/images/openclipart/online-learning.svg" alt="image of book open above globe representing a cycle of knowledge" />
                            </div>

                            <h3 className='display-5 font-weight-very-bold text-black amatic-font'>Repeat.</h3>
                            <Card.Body className='d-flex align-items-center'>

                                <div className='purple-dashed-border px-2 py-2 bg-color-purple-gradiant text-white fs-5' style={{ minHeight: "4rem" }}>Quality Tests. Top-notch instruction. Maximize your potential.</div>

                            </Card.Body>
                        </Card>
                    </Button>
                </Col>
            </Row>
            <TutorToolsBanner />
            {isActiveSubscription ?
                <Row>

                </Row>
                :
                <Row className='bg-color-white py-4 d-flex justify-content-center'>
                    <Col sm={6} className='text-center'>
                        <h3 className='text-center text-green font-weight-bold'>Try the best site for Digital SAT prep!</h3>
                        <Button className='purple-button hover-enlarge text-center'
                            onClick={handleSubscribeClick}
                        >
                            Subscribe Today
                        </Button>
                    </Col>

                </Row>
            }



            <UnderConstructionModal
                show={showUnderConstructionModal}
            />
            <footer className='text-center'>
                <Row>
                    <Col>
                        <a className="purple-text" href="/privacy-policy.html">Privacy Policy</a>
                    </Col>
                    <Col>
                        <a className="purple-text" href="/terms-and-conditions.html">Terms and Conditions</a>
                    </Col>
                    <Col>
                        <a className="purple-text" href="/refund-policy.html">Refund Policy</a>
                    </Col>
                    <Col>
                        <a className="purple-text" href="/cookie-policy.html">Cookie Policy</a>
                    </Col>
                </Row>
                <div className=''><small>SAT® is a trademark registered by the College Board, which is not affiliated with, and does not endorse, this product or website.</small></div>
                <div className=''><small>ACT® is a trademark registered by ACT, which is not affiliated with, and does not endorse, this product or website.</small></div>
                <div><small>&copy; Copyright 2023, Resolve Prep LLC</small></div>

            </footer>
        </Container>
    )
}

export default StartPage